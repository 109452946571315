import React, { Component } from 'react';
import { connect } from 'react-redux';


class Profilecomponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardRemove: false,
            isFullScreen: false,
            isCollapsed: false,
        };
    }
    render() {
        const { user, campus, section } = this.props;
        return (
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-body w_user">
                            <div className="user_avtar">
                                <img className="rounded-circle" src="../assets/images/sm/avatar-10.png" alt="" />
                            </div>
                            <div className="wid-u-info">
                                <h5>{user.firstName}</h5>
                                <p className="text-muted m-b-0">{`${user.lastName} ${user.surname}`} </p>
                                {/* <ul className="list-unstyled">
                                    <li>
                                        <h5 className="mb-0">270</h5>
                                        <small>Followers</small>
                                    </li>
                                    <li>
                                        <h5 className="mb-0">310</h5>
                                        <small>Following</small>
                                    </li>
                                    <li>
                                        <h5 className="mb-0">908</h5>
                                        <small>Liks</small>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Sobre Mi</h3>
                            <div className="card-options ">
                                {/* <a  className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                                <a  className="card-options-remove" data-toggle="card-remove"><i className="fe fe-x"></i></a> */}
                            </div>
                        </div>
                        <div className="card-body">
                            {/* <p>Hola soy  {user.fullName}</p> */}
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <b>DNI</b>
                                    <div>{user.dni}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Código de Estudiante</b>
                                    <div>{user.code}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Genero </b>
                                    <div>{user.gender === 'F' ? 'Femenino' : 'Masculino'}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Fecha de Nacimiento </b>
                                    <div>{user.birthdate}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Año Lectivo </b>
                                    <div>2024</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Aula </b>
                                    <div>{section}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>Sede </b>
                                    <div>{campus.name}</div>
                                </li>
                                {/* <li className="list-group-item">
                                    <div className="clearfix">
                                        <div className="float-left"><strong>Telly</strong></div>
                                        <div className="float-right"><small className="text-muted">35%</small></div>
                                    </div>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar bg-azure" role="progressbar" style={{ width: "35%" }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="clearfix">
                                        <div className="float-left"><strong>Account</strong></div>
                                        <div className="float-right"><small className="text-muted">72%</small></div>
                                    </div>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar bg-red" role="progressbar" style={{ width: "72%" }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="clearfix">
                                        <div className="float-left"><strong>MS Office</strong></div>
                                        <div className="float-right"><small className="text-muted">60%</small></div>
                                    </div>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar bg-blue" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    dropDownMenu: state.settings.isDropDownMenu
})

const mapDispatchToProps = dispatch => ({
    
})
export default connect(mapStateToProps, mapDispatchToProps)(Profilecomponent);