// Import all components here

import LoginPage from '../pages/LoginPage';
import Signup from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import InternalServer from './Authentication/500';
import DashboardStudent from '../pages/DashboardStudent';

const Routes = [
  {
      path: '/',
      name: 'dashboard',
      exact: true,
      pageTitle: 'Dashboard',
      component: DashboardStudent
  },
  {
      path: '/login',
      name: 'login',
      exact: true,
      pageTitle: 'Tables',
      component: LoginPage
  },
  {
      path: '/signup',
      name: 'signup',
      exact: true,
      pageTitle: 'Tables',
      component: Signup
  },
  {
      path: '/forgotpassword',
      name: 'forgotpassword',
      exact: true,
      pageTitle: 'Tables',
      component: ForgotPassword
  },
  {
      path: '/notfound',
      name: 'notfound',
      exact: true,
      pageTitle: 'Tables',
      component: NotFound
  },
  {
      path: '/internalserver',
      name: 'internalserver',
      exact: true,
      pageTitle: 'Tables',
      component: InternalServer
  },
];

export default Routes;
