import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import { connect } from 'react-redux';
import { addUser } from '../../redux/actions/user';
import { addCampus } from '../../redux/actions/campus';
import has from 'lodash/has';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import AppHelper from "../../helpers/AppHelper";

const LoginSchema = yup.object().shape({
  dni: yup.number().required("Campo requerido"),
  password: yup.string().required("Campo requerido"),
});

const Login = (props) => {
  const history = useHistory();
  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(LoginSchema)
    // defaultValues: { dni: "", password: "" }
  });
  const [loading, setLoading] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    if (!setRequestFailed) {
      setErrorMessage('');
    }
  }, [requestFailed]);

  async function login (dataLogin) {
    setLoading(true);
    try {
      setRequestFailed(false);

      const response = await AuthService.login(dataLogin);

      // console.log(response);
      const accessToken = response.data.accessToken;
      UserService.setAccessToken(accessToken);

      const getUser = await UserService.me();
      const modules = await UserService.modules();
      if (!getUser || !has(getUser, 'data')) {
        throw {message: 'No se puede actualizar la informacion del usuario'}
      }

      const user = getUser.data;
      let payload = {
        ...user,
        accessToken,
        modules: modules.data,
        school: 'MONSERRAT'
      };
      const campus = getUser.data.campuses[0];
      let payloadCampus = {
        ...campus
      }
      props.dispatch(addUser(payload));
      props.dispatch(addCampus(payloadCampus));
      setLoading(false);
      switch (user.role) {
        case 'super-admin':
        case 'admin':
            props.dispatch({type: 'LOGOUT'});
            history.push('/login');
          break;
        case 'coordinator-main': 
          history.push('/login');
        case 'coordinator':
        case 'tutor':
          history.push('/login');
        case 'coordinator-main':
        case 'teacher':
          history.push('/login');
          break;
        default: history.push('/');
      }

    } catch(error) {
      console.log(error);
      setLoading(false);
      setRequestFailed(true);
      setErrorMessage({message: AppHelper.getError(error)});
    }
  }

  // const handleChange = e => {
  //   console.log(e.target);
  //   const {name , value} = e.target
  //   setData(prevState => ({
  //     ...prevState,
  //     [name] : value
  //   }))
  // }

  const onSubmit = useCallback((formData) => {
    // You can use the validated data to make your API request here
    const payload = {
      'dni': formData.dni,
      'password': formData.password,
    }
  
    login(payload);

    reset();
  }, [reset]);

  return (
    <>
      {
        loading &&
        <div className="page-loader-wrapper">
          <div className="loader">
          </div>
      </div>
      }
      <div className="auth option2">
        <div className="auth_left">
          <div className="card">
            <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
              <div className="text-center">
                <Link className="header-brand" to="/">
                  <i className="fa fa-graduation-cap brand-logo"></i>
                </Link>
                <div className="card-title mt-3">Ingresa a tu cuenta</div>
                { requestFailed &&
                  <span className="text-danger mb-10"> { errorMessage.message} </span>
                }
              </div>
              <div className="mt-15"></div>
              <div className="form-group">
                <input
                  type="text"
                  name="dni"
                  {...register("dni")}
                  className={errors.dni ? "form-control is-invalid" : "form-control"}
                  aria-describedby="emailHelp"
                  placeholder="Código"
                  // onChange={handleChange}
                  // value={setData.dni}
                  // ref={register}
                  // inputRef={register}
                  maxLength={8}
                />
                {errors.dni && <span className="invalid-feedback">{errors.dni.message}</span>}
              </div>
              <div className="form-group">
                {/* {forgotPassword &&
                <label className="form-label"><Link to="forgotpassword" className="float-right small">Recuperar
                  contraseña</Link></label> }
                */}
                <input
                  type="password"
                  name="password"
                  className={errors.password ? "form-control is-invalid" : "form-control"}
                  placeholder="Contraseña"
                  // onChange={handleChange}
                  // value={setData.password}
                  // ref={register}
                  {...register("password")}
                  // {...register("password")}
                />
                {errors.password && <span className="invalid-feedback">{errors.password.message}</span>}
              </div>
              {/* <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Recordarme</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button type="submit" className="btn btn-primary btn-block" title="">Ingresar</Button>
                {/* <div className="text-muted mt-4">Don't have account yet? <Link to="/signup">Sign Up</Link></div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default connect(null)(Login);
